<template>
    <div
        class="preview-container"
        @mouseover="showPlayButtons"
        @mouseleave="hidePlayButtons">
        <div class="preview-ui">
            <div class="top-elements">
                <div class="top-info">
                    <img
                        v-if="pageLogo"
                        class="logo"
                        :src="pageLogo">
                    <div
                        v-else
                        class="placeholder-logo">
                        YOUR
                        LOGO
                    </div>
                    <div class="top-desc">
                        <div class="top-title">
                            {{ pageName }}
                        </div>
                        <div class="top-sponsored">
                            Sponsored
                        </div>
                    </div>
                </div>
                <div class="top-dots">
                    <icon
                        class="top-dot"
                        size="5"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="5"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="5"
                        color="black"
                        name="circle" />
                </div>
            </div>
            <div
                class="primary-text"
                :class="{'fixed-height': seeMore}">
                <!-- eslint-disable-next-line -->
                <div ref="primary" style="line-height:21px">{{ primary }}
                    <span
                        v-if="placeholderActive"
                        class="primary-placeholder" />
                </div>
                <span
                    v-if="seeMore"
                    class="seemore"
                    @click="openPrimary">...See more</span>
            </div>
            <div
                class="image-holder"
                :class="{'square-style': squareStyle, 'video-square-style': videoSquareStyle}">
                <div v-if="assetType === 'IMAGE'">
                    <img
                        :key="firstAssetUrl"
                        class="preview-image"
                        :src="firstAssetUrl">
                </div>
                <div
                    v-else-if="assetType === 'VIDEO'"
                    class="video-holder">
                    <div
                        v-if="controlsActive"
                        class="play-wrapper"
                        @click="playVideo">
                        <div class="play">
                            <icon
                                v-if="!hidePlayIcon"
                                size="40"
                                color="white"
                                name="audio-play" />
                            <div v-else>
                                <div class="previews-pause-bar" />
                                <div class="previews-pause-bar" />
                            </div>
                        </div>
                    </div>
                    <video
                        :key="firstAssetUrl"
                        ref="video"
                        loop
                        :muted="muted"
                        class="preview-video">
                        <source
                            :src="firstAssetUrl"
                            type="video/mp4">
                    </video>
                </div>
            </div>
            <div class="bottom-elements">
                <div class="bottom-info">
                    <div class="bottom-site">
                        {{ sanitizedCtaLink }}
                    </div>
                    <div class="bottom-title">
                        {{ sanitizedHeadline }}
                    </div>
                    <div class="bottom-desc">
                        {{ sanitizedDescription }}
                    </div>
                </div>
                <div class="bottom-link">
                    <a
                        :href="ctaLink"
                        target="_blank">{{ ctaText }}</a>
                </div>
            </div>
            <div class="low-elements">
                <div class="align-flex">
                    <icon
                        size="20"
                        color="#606770"
                        name="thumbsup" />
                    <div class="icon-text">
                        Like
                    </div>
                </div>
                <div class="align-flex">
                    <icon
                        size="20"
                        color="#606770"
                        name="comments" />
                    <div class="icon-text">
                        Comment
                    </div>
                </div>
                <div class="align-flex">
                    <icon
                        size="20"
                        color="#606770"
                        name="arrow-share" />
                    <div class="icon-text">
                        Share
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';
import { removeHttp } from '@/helpers/removeHttpFromUrl';
import { mapGetters, mapState } from 'vuex';
import { getAssetStyles } from '@/helpers/getAssetStyles';
import {
    ASSET_STYLE_PORTRAIT,
    ASSET_STYLE_SQUARE,
    ASSET_STYLE_VIDEO,
    VIDEO_ASSET_TYPE,
    FACEBOOK,
    headlineKey,
    descriptionKey
} from '@/components/ad-studio/store/constants';

export default {
    components: {
        Icon
    },
    props: {
        adData:{
            type: [Object, Array],
            required: true
        },
        muted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            squareStyle: false,
            videoSquareStyle: false,
            hidePlayIcon: false,
            controlsActive: true,
            defaultLogoUrl: 'https://www.facebook.com/images/fb_icon_325x325.png',
            seeMore: false,
            primary: '',
            fullPrimary: '',
            placeholderActive: true
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters(['dealerPrimaryLogo']),
        pageName() {
            return this.profileName || this.currentDealer.name || this.adData?.platform_specific_fields?.snapchat_page?.name;
        },
        pageLogo() {
            return this.profileLogo || this.dealerPrimaryLogo?.url || this.defaultLogoUrl;
        },
        profileName() {
            return this.adData?.platform_specific_fields?.profile?.name;
        },
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        creatives() {
            return this.adData?.creatives ?? [];
        },
        firstAsset() {
            return this.creatives[0]?.assets.find(asset => asset.aspect_ratio === '9:16') ?? this.creatives[0]?.assets?.[0] ?? {};
        },
        firstAssetUrl() {
            return this.firstAsset?.url;
        },
        assetType() {
            return (this.creatives[0]?.media_type || this.firstAsset?.asset_type?.name)?.toUpperCase();
        },
        sanitizedHeadline() {
            // confirm keys
            const headline = this.adData?.headline || this.creatives[0]?.headline;
            return headline?.slice(0, lengthValidatorConstants[headlineKey][FACEBOOK].maxLength);
        },
        sanitizedDescription() {
            // confirm keys
            const description = this.adData?.description || this.creatives[0]?.description;
            return description?.slice(0, lengthValidatorConstants[descriptionKey][FACEBOOK].maxLength);
        },
        sanitizedCtaLink() {
            return removeHttp(this.adData?.cta_link);
        },
        ctaLink() {
            return this.adData?.cta_link || this.creatives?.cta_link;
        },
        ctaText() {
            return this.adData?.cta_text || this.creatives?.cta_text;
        },
        primaryText() {
            return this.adData.primary_text;
        }
    },
    watch:{
        adData:{
            handler() {
                this.formatPrimaryText();
                if (this.adData.platform_controls_media_styles) {
                    this.initPlatformMediaStyles();
                }
            },
            deep: true
        },
        primaryText() {
            this.formatPrimaryText();
        }
    },
    mounted() {
        if (this.adData.platform_controls_media_styles) {
            this.initPlatformMediaStyles();
        }
        this.formatPrimaryText();
        if (this.assetType.toLowerCase() === VIDEO_ASSET_TYPE) {
            this.$emit('set-video-type');
        }
    },
    methods: {
        resetPlatformStyles() {
            this.squareStyle = false;
            this.videoSquareStyle = false;
        },
        async initPlatformMediaStyles() {
            this.resetPlatformStyles();
            const assetStyles = await getAssetStyles(this.firstAssetUrl, this.assetType);
            if (
                assetStyles.includes(ASSET_STYLE_PORTRAIT) ||
                assetStyles.includes(ASSET_STYLE_SQUARE)
            ) {
                this.squareStyle = true;
            }
            if (
                assetStyles.includes(ASSET_STYLE_PORTRAIT) && assetStyles.includes(ASSET_STYLE_VIDEO) ||
                assetStyles.includes(ASSET_STYLE_SQUARE) && assetStyles.includes(ASSET_STYLE_VIDEO)
            ) {
                this.videoSquareStyle = true;
            }
        },
        formatPrimaryText() {
            // detailed formatting of primary text like we have it in FB business manager
            this.seeMore = false;
            let rawPrimaryText = this.primaryText;
            if (rawPrimaryText) {
                rawPrimaryText = rawPrimaryText.trim();
                this.primary = rawPrimaryText;
                this.fullPrimary = rawPrimaryText;
                const lines = rawPrimaryText.split(/\r\n|\r|\n/);
                this.checkLines(lines);
            }
        },
        openPrimary() {
            this.seeMore = false;
            this.primary = this.fullPrimary;
            this.placeholderActive = false;
        },
        checkLines(lines) {
            this.$nextTick(function() {
                const primary = this.$refs.primary;
                const textHeight = primary.offsetHeight;
                const lineHeight = parseInt(primary.style.lineHeight);
                const textLines = textHeight / lineHeight;
                if (lines.length > 3) {
                    this.seeMore = true;
                    const shortenedText = [];
                    lines.forEach((line, index) => {
                        if (index < 3) {
                            shortenedText.push(line);
                        }
                    });
                    this.primary = shortenedText.join('\n');
                    this.primary = this.primary.trim();
                }
                if (lines[lines.length - 1] == '') {
                    lines.splice(lines.length - 1, 1);
                    this.checkLines(lines);
                    return;
                }
                if (textLines > 3) {
                    this.seeMore = true;
                    let lastLine = lines[lines.length - 1];
                    if (lastLine) {
                        const lineWords = lastLine.split(' ');
                        // eslint-disable-next-line no-unused-vars
                        const lastWord = lineWords.pop();
                        lines[lines.length - 1] = lineWords.join(' ');
                        const shortenedText = [];
                        lines.forEach(line => {
                            shortenedText.push(line);
                        });
                        this.primary = shortenedText.join('\n');
                        this.primary = this.primary.trim();
                        // must be recursive until we get desired length
                        setTimeout(() => {
                            this.checkLines(lines);
                        }, 0);
                    }
                }
            });
        },
        playVideo() {
            if (this.hidePlayIcon) {
                this.$refs.video.pause();
                this.hidePlayIcon = false;
            } else {
                this.$refs.video.play();
                this.hidePlayIcon = true;
            }
        },
        showPlayButtons() {
            this.controlsActive = true;
        },
        hidePlayButtons() {
            if (this.hidePlayIcon) {
                this.controlsActive = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-container{
    position: relative;
    width: 375px;
    // min-height: 872px;
    display: flex;
    flex-direction: column;
    background-color:$white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
    @media only screen and (max-width: 599px) {
        width: 375px;
        min-height: 745px;
    }
    @media only screen and (max-width: 400px) {
        width: 320px;
        min-height: 685px;
    }
}
.preview-ui{
    position: relative;
    display: flex;
    height:100%;
    flex-direction: column;
}
.top-elements{
    // border-top: 1px solid $gray-light;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 15px;
}
.top-dots{
    white-space: nowrap;
}
.top-dot{
    display: inline-block;
    margin-left: 5px;
}
.logo{
    width:40px;
    height:40px;
    border-radius: 50%;
}
.placeholder-logo{
    width:40px;
    height:40px;
    border-radius: 50%;
    border:1px solid white;
    color:white;
    background:black;
    text-align: center;
    font-weight:900;
    font-size: 9px;
    padding-top:8px;
    line-height: 11px;
}
.top-info{
    display: flex;
}
.top-desc{
    margin-left:8px;
}
.top-title{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    margin-top:2px;
}
.top-sponsored{
    font-size: 13px;
    color: $gray;
}
.primary-text{
    font-size: 14px;
    padding: 15px;
    overflow: hidden;
    position: relative;
    white-space: pre-wrap;
}
.primary-placeholder{
    width: 90px;
    background: transparent;
    height: 1px;
    display: inline-block;
}
.seemore{
    background: white;
    padding-left:5px;
    margin-top: 20px;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    color: #03A2EA;
    position: absolute;
    bottom:15px;
    right:15px;
    &:hover{
        text-decoration: underline;
    }
}
.image-holder{
    background: black;
    width: 100%;
    // height: 500px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        display: inline-block;
        max-width: 100%;
        max-height: 667px;
        height: auto;
        vertical-align: top;
    }
    video{
        display: inline-block;
        max-width: 100%;
        max-height: 667px;
        height: auto;
        vertical-align: top;
    }
    @media only screen and (max-width: 599px) {
        height:375px;
    }
    @media only screen and (max-width: 400px) {
        height: 320px;
    }
}
.image-holder.square-style {
    max-height: 375px;
    overflow: hidden;
    img, video {
        width: 375px;
        max-height: none;
    }
}
.image-holder.video-square-style {
    max-height: 475px;
}
.video-holder{
    width: 100%;
    // height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-text {
    margin-left: 7px;
    color: rgba(100,103,107,1);
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.bottom-elements{
    display: flex;
    justify-content: space-between;
    padding: 15px 27px;
    background: #F2F2F6;
}
.bottom-site{
    font-size: 13px;
    color: #6C6D71;
}
.bottom-title{
    font-size: 15px;
    color:black;
    font-weight: 700;
}
.bottom-desc{
    font-size: 13px;
    color: #6C6D71;
}
.bottom-link a{
    width: 121px;
    height: 36px;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #E5E6EB;
    border-radius: 5px;
    color:black;
    text-decoration: none;
    font-weight: 700;
    margin-top:15px;
    &:hover{
        background: $gray-light;
    }
}
.low-elements{
    padding:10px;
    display: flex;
    justify-content: space-around;
}
.play-wrapper{
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    cursor: pointer;
    z-index: 3;
    .play{
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-20px;
        margin-left:-20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
    &:after{
        content:'';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top:50%;
        left:50%;
        margin-top:-44px;
        margin-left:-45px;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        z-index: 1;
    }
}
.fixed-height {
    height: 93px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 15px;
        background: $white;
    }
}

</style>
